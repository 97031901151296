import { createRouter, createWebHistory } from 'vue-router';
import Home from '@views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "fedTools" */ '@views/Test'),
    meta: {
      title: 'Test Page'
    }

  },
  {
    path: '/components',
    name: 'Components',
    component: () => import(/* webpackChunkName: "fedTools" */ '@views/Components'),
    meta: {
      title: 'Components'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'Page Not Found',
    component: () => import(/* webpackChunkName: "special" */ '@views/PageNotFound'),
    props: true,
    meta: {
      title: 'Page Not Found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes, // This is required.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: smooth
      }
    } else {
      return { top: 0 }
    }
  }
})

export default router