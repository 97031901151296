<template>
	<header id="header" class="header d-flex align-items-center">
		<nav class="navbar navbar-light navbar-expand-xl bg-white fixed-top border-bottom">
			<div class="container">
				<a class="navbar-brand" href="/">
					<img
						alt="link to home page"
						class="logo"
						src="@img/qsd-logo.svg"/>
				</a>
				<a class="btn btn-orange" href="mailto:info@qsitedesigns.com">Request a quote</a>
				<!-- <button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div
					class="offcanvas offcanvas-end"
					tabindex="-1"
					id="offcanvasNavbar"
					aria-labelledby="offcanvasNavbarLabel">
					<div class="offcanvas-header">
						<h5 class="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
						<button
							type="button"
							class="btn-close text-reset"
							data-bs-dismiss="offcanvas"
							aria-label="Close">
						</button>
					</div>
					<div class="offcanvas-body">
						<ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
							<li class="nav-item">
								<a
									class="nav-link active"
									aria-current="page"
									href="#">
									Home
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#">Link</a>
							</li>
							<li class="nav-item dropdown">
								<a
									class="nav-link dropdown-toggle"
									href="#"
									id="offcanvasNavbarDropdown"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									Dropdown
								</a>
								<ul class="dropdown-menu" aria-labelledby="offcanvasNavbarDropdown">
								<li><a class="dropdown-item" href="#">Action</a></li>
								<li><a class="dropdown-item" href="#">Another action</a></li>
								<li>
									<hr class="dropdown-divider">
								</li>
								<li><a class="dropdown-item" href="#">Something else here</a></li>
								</ul>
							</li>
						</ul>
						<form class="d-flex">
						<input
							class="form-control me-2"
							type="search"
							placeholder="Search"
							aria-label="Search">
						<button class="btn btn-outline-success" type="submit">Search</button>
						</form>
					</div>
				</div> -->
			</div>
		</nav>
	</header>
</template>

<script>
import { Dropdown } from 'bootstrap';
export default {
	name: 'Header',
	components: {
		Dropdown
	}
};
</script>