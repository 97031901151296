<template>
	<a
		id="skipNav"
		href="#mainContent"
		class="btn skip-to-content">
		<span class="skip-nav-text">Skip to main content</span>
	</a>
	<Header/>
	<main class="main">
		<Home/>
	</main>
	<!-- <footer class="pt-5 my-5 text-muted">
		&copy; 2021
	</footer> -->
</template>

<script>
import Header from '@components/Header.vue';
import Home from '@views/Home.vue';
export default {
	components: {
		Header,
		Home
	},
	watch: {
		$route(to, from, next) {
			document.title = to.meta.title + ' | Qsite Designs';
		}
	}
}
</script>