<template>
	<div class="container">
		<div class="p-5 mb-4 bg-light rounded-3">
			<div class="container-fluid py-5">
				<h1 class="visually-hidden">Home</h1>
				<h2>Website development &amp; hosting</h2>
				<p class="lead">Qsite Designs is a website development and hosting provider located in Minneapolis, MN.</p>
				<p>We work with sole-proprietors as well as small and medium size businesses. If you're an existing organization or just getting started,LET US take the hassle of website development on so that you don't have to. Afterall that is our specialty!</p>
				<p class="">For more information please email us at <a href="mailto:info@qsitedesigns.com">info@qsitedesigns.com</a>.</p>
				<div class="ms-auto mb-3">
					<a class="btn btn-orange" href="mailto:info@qsitedesigns.com">Request a quote</a>
				</div>
				<p><small><em>Our fully functional website will be up and running shortly.</em></small></p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Home'
};
</script>
<style lang="scss" scoped>
</style>